/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

const $ = require("jquery");
import * as bootstrap from 'bootstrap';

// include this here
import "select2/dist/css/select2.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";

import "@fortawesome/fontawesome-free/css/all.css";

import "@github/clipboard-copy-element";

Rails.start();
ActiveStorage.start();

// stylesheets
import "../stylesheets/application.scss";

window.addEventListener("load", function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
});

window.addEventListener("load", function () {
  document.querySelectorAll('div.floating-alert[data-timeout]').forEach((alert) => {
    const timeout = parseInt(alert.dataset.timeout);
    window.setTimeout(() => alert.remove(), timeout);
  })

  document.addEventListener('clipboard-copy', function (event) {
    const tooltip = new bootstrap.Tooltip(event.target, {
      title: 'Copied to clipboard!',
      placement: 'right',
      trigger: 'manual',
      customClass: 'copy-success-tooltip'
    });
    tooltip.show();
    window.setTimeout(() => { 
      tooltip.dispose();
    }, 3000);
  });
});


// define these globally for use by javascript snippets
window.jQuery = $;
window.$ = $;
window.Rails = Rails;

// global helper functions
window.getOrCreateBootstrapModal = element => {
  return bootstrap.Modal.getOrCreateInstance(element);
}

window.getBootstrapModal = element => {
  return bootstrap.Modal.getInstance(element);
}
